/* HeroBanner design */
/* old Code */

/* .herobanner {
  background: linear-gradient(to top, #2f2f32ba, #4543479b),
    url("../components/Assets/BackgroundMain.jpg") no-repeat top center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  height: 730px;
  min-height: 600px;
} */

/* New code */
.herobanner {
  background: linear-gradient(to top, #2f2f32ba, #4543479b),
    url("../components/Assets/BackgroundMain.jpg") no-repeat top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 730px;
  min-height: 600px;
}

.herobanner-slider .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: start;
}

.homepage.text-with-bg {
  color: #fff;
  font-size: 2.5rem;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

.text-white {
  color: white;
}
/* byeeee */
/* .bg-bannerimage {
  background-image: url("../../public/Assets/bg-image.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
} */

.bg-bannerimagefaq {
  background-image: url("../components/Assets/bgfaq.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.herobanneravi {
  background-image: url("../../public/Assets/aviationbanner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 800px;
}

.herobanneraviman {
  background-image: url("../components/Assets/carriercover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.herobanneravimans {
  background-image: url("../components/Assets/servicebanner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.herobannermission {
  /* background-image: url("../../public/Assets/jumbo.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: white;
  background: #f2f6f5;
}

.herobannermission h1,
.herobannermission h2 {
  font-weight: bold;
}

.herobannermission p {
  font-size: 0.8rem;
  line-height: 1.8;
}
@media (max-width: 468px) {
  .herobannermission p {
    text-align: justify;
    font-size: 15px;
    line-height: 1.8;
  }
}
@media (max-width: 1024px) {
  .herobannermission p {
    text-align: justify;
    font-size: 14px;
    line-height: 1.5;
  }
}
.colorstyle {
  font-size: 1.8rem !important;

  color: #000608 !important;
}
.colorstyle-industry {
  font-size: 1.8rem !important;

  color: #e02454 !important;
}

.bg-dark.bg-opacity-75 {
  background-color: rgba(0, 0, 0, 0.75);
}

.shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.card {
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.02);
}

.card-title {
  font-size: 0.8rem;
  font-weight: bold;
}

.card-body {
  padding: 1.25rem;
}

.heightadjust {
  min-height: 175px !important;
}

.pstyle {
  text-align: start;
  line-height: 2.5vh;
  font-size: 0.8rem !important;
}
@media (max-width: 1024px) {
  .pstyle {
    text-align: center;
    line-height: 2.5vh;
    font-size: 14px !important;
  }
}
.inner-hero-banner p {
  color: #fff;
}
.herobanner p {
  color: var(--whiteColor);
}

.section .inner-text {
  padding-top: 20%;
  color: var(--whiteColor);
}

.section .inner-text h1 {
  font-weight: 600;
}

.section .inner-text p {
  font-weight: 600;
  padding-top: 10px;
}

@media screen and (max-width: 767px) {
  .section .inner-text {
    padding-top: 30%;
    color: var(--whiteColor);
  }

  .section .inner-text h1 {
    font-weight: 600;
    font-size: 30px;
  }
  .inner-two {
    padding-bottom: 20px;
  }
  .expertise .bg-exp-data {
    background-color: #59aa47 !important;

    height: 520px !important;
  }
}

/* count design */
.section-two {
  padding-top: 50px;
  padding-bottom: 50px;
}
.section h3 {
  font-size: 50px;
  font-weight: 600;
}
.count-design {
  background-color: #00bcff;
}
.count-inner {
  border-right: 2px solid #fff;
}
.count-inner h2 {
  padding-left: 10px;
  font-weight: 700;
  color: var(--whiteColor);
}
.count-inner-4 h2 {
  padding-left: 10px;
  font-weight: 700;
  color: var(--whiteColor);
}
.count-inner h2 span {
  font-size: 15px;
}

.count-design p {
  font-size: 18px;
  color: var(--whiteColor);
  text-align: revert-layer;
}

.counter-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
/* {Turnkey MEP Solutions} */

.two-right .inner-two h6 {
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid var(--bgColorPrimary);
  border-radius: 1px;
  box-shadow: 2px 1px;
}
.two-right {
  background-image: url("../../public/Assets/Bgimage.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 800px; */
}
.two-left {
  background-image: url("../../public/Assets/Bgimage.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 800px; */
}

.two-right .inner-two {
  display: flex;
  align-items: baseline;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}
.two-right h3 {
  font-size: 50px;
  font-weight: 600;
  padding-bottom: 20px;
}

.section h6 {
  color: #59aa47;
  font-size: 16px;
  text-transform: uppercase;
  /* text-transform: capitalize; */
}

p.paragraph {
  color: #8b8e8f !important;
}

/* expertise */

.expertise {
  background-color: #f2f2f2;
}

.expertise .col-lg-6 a {
  justify-content: end;
}

/* .bg-exp {
   background-image: url('/images/exp0.png');
   background-repeat: no-repeat;
   background-size: cover;
   border-radius: 9px;
} */

.expertise .bg-exp-data {
  display: flex;
  align-items: baseline;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}
.bg-exp {
  padding-left: 25px;
  padding-right: 20px;
}
.expertise .bg-exp-data h6 {
  color: var(--whiteColor);
  font-size: 10px;
}

.expertise .bg-exp-data h3 {
  color: var(--whiteColor);
  font-size: 20px;
}

.expertise .bg-exp-data p {
  color: var(--whiteColor);
  font-size: 10px;
}

.section .bg-exp-data {
  /* border: 1px solid transparent; */

  transition: border-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  border-radius: 25px;
  width: 100%;
}

.section .bg-exp-data:hover {
  border-color: var(--bgColorPrimary);
  opacity: 0.8;
  border-radius: 15px;
}

.expertise .col-lg-3 {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  /* height: 100vh;  */
}
/* quality */

.quality {
  background-color: #f2f2f2;
}

.inner-hero-banner .inner-text {
  display: flex;
  align-items: baseline;
  height: 60%;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-left: 25px;
  justify-content: center;
}

.line {
  background-color: var(--bgColorPrimary);
  height: 5px;
  width: 100px;
}

.section .primary-text {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  padding-bottom: 50px;
  text-transform: uppercase;
}
.section .primary-text span {
  color: var(--bgColorPrimary);
}

/* .solution-one{
   background-image: url('/images/solution-one.png');
   background-repeat: no-repeat;
   background-size: cover;
} */

.solution-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
  justify-content: center;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.section .section-slider .solu-heading-tag {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding-top: 15px;
  align-items: center;
  text-align: center;
}

.section .section-slider span {
  color: var(--bgColorPrimary);
  padding-top: 5px;
}

.herobannercourse {
  background-image: url("../../public/Assets/mainbanner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  background-attachment: fixed;
}

.imageborder {
  height: 150px;
  width: 150px;
  border-radius: 200px !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
  margin-top: 10px;
}

/* src/Styles/globals.css */

/* Herobanner with background image and overlay */
.herobannerbatch {
  position: relative;
  background-image: url("../../public/Assets/batch.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  background-attachment: fixed;
  display: flex;
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
}

.herobannerbatch::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Black overlay with 50% opacity */
  z-index: 1; /* Ensure the overlay is above the background image */
}

.herobannerbatch .container {
  position: relative;
  z-index: 2;
  color: white;
}

.herobannerbatch h1 {
  color: white;
  z-index: 2;
  text-align: center;
}

.coursebg {
  background-image: url("../../public/Assets/bgcourse.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5;
  /* background-color: #00BCFF; */
}

.titlenamec {
  white-space: nowrap;
}

/* src/Styles/globals.css */

/* Testimonials */
/* Testimonial Section */
.testimonial-section {
  background-color: #f9f9f9;
  padding: 60px 20px;
  text-align: center;
}

.section-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 40px;
  font-weight: 700;
}

.testimonial-slider {
  max-width: 1100px;
  margin: 0 auto;
}

.testimonial-card {
  border-radius: 10px;
  padding: 30px;
  text-align: center;
}

.testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 20px;
}

.testimonial-description {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 20px;
  font-style: italic;
  line-height: 1.6;
}

.testimonial-author {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
}

.testimonial-author span {
  font-size: 0.95rem;
  color: #777;
  font-weight: 400;
}
@media (max-width: 768px) {
  .splide__slide {
    width: 100% !important;
  }
}

@media (max-width: 1024px) {
  .splide__slide {
    width: 50% !important;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .testimonial-card {
    padding: 20px;
  }

  .testimonial-description {
    font-size: 1rem;
  }

  .section-title {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .testimonial-description {
    font-size: 0.95rem;
  }

  .testimonial-author {
    font-size: 0.9rem;
  }
}

/* placement */

.solution-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
  justify-content: center;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.imgDiv {
  width: 100%;
}

.solution-itemgrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  border-radius: 10px;
}
.cebtt {
  text-align: center !important;
}

@media (max-width: 1200px) {
  .solution-itemgrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 992px) {
  .solution-itemgrid {
    grid-template-columns: 1fr 1fr;
    gap: 0px;
  }
}

@media (max-width: 768px) {
  .solution-itemgrid {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .cebtt {
    margin-left: 10px;
  }
}
@media (max-width: 468px) {
  .solution-itemgrid {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.solution-itemgridsec {
  margin: 10px;

  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.solution-itemgridseccate {
  background-color: white;

  padding: 2;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.solution-itemgridcate {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  border-radius: 10px;
}
.cate {
  color: #1e71bc !important;
  text-transform: capitalize !important;
  margin-left: 5px !important;
  cursor: pointer;
  text-align: center !important;
}

.hovereffect {
  color: black !important;
  transition: color 0.3s ease, text-shadow 0.3s ease, transform 0.3s ease; /* Add transitions */
  position: relative; /* For the underline effect */
}

.hovereffect:hover {
  color: #580fb7 !important;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Add text shadow */
  transform: scale(1.05); /* Slightly increase the size */
}

/* Optional: Add an underline effect */
.hovereffect::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: #580fb7;
  transition: width 0.3s ease;
}

.hovereffect:hover::after {
  width: 70%;
  left: 0;
  background: #580fb7;
}

.hovereffecthead {
  color: #2596be !important;
  transition: color 0.3s ease, text-shadow 0.3s ease, transform 0.3s ease; /* Add transitions */
  position: relative; /* For the underline effect */
}

.hovereffecthead:hover {
  color: #580fb7 !important;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Add text shadow */
  transform: scale(1.05); /* Slightly increase the size */
}

/* Optional: Add an underline effect */
.hovereffecthead::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: #580fb7;
  transition: width 0.3s ease;
}

.hovereffecthead:hover::after {
  width: 70%;
  left: 0;
  background: #580fb7;
}

.faq {
  font-weight: 900 !important;
}
.faqimage {
  width: 250px;
  height: 250px;
}

.homepage {
  font-weight: 900 !important;
}

.text-with-bg {
  /* background-color: rgba(0, 0, 0, 0.5); */
  color: rgb(158 241 255); /* Sky blue text */
  /* padding: 10px; Adds some space around the text */
  border-radius: 5px; /* Optional rounded corners */
  display: inline-block;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  /* font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif; */
  font-size: 42px;
}
@media (max-width: 768px) {
  .herobanner {
    background-size: cover; /* Make the background cover the entire container */
    background-position: center; /* Center the background */
    height: 500px;
    min-height: 400px; /* Adjust the minimum height */
  }
}

@media (max-width: 480px) {
  .herobanner {
    background-size: cover;
    background-position: center;
    height: 450px;
    min-height: 300px;
  }
}
