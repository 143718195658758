.industries-section {
  padding: 50px 20px;
  background: #f9f9f9;
  text-align: center;
}

.header h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.header p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 40px;
}

.industries-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(244px, 1fr));
  gap: 20px;
}

.industry-card {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.industry-card h3 {
  font-size: 1.5rem;
  color: #007bff;
  margin-bottom: 10px;
}

.industry-card p {
  font-size: 1rem;
  color: #555;
}
@media (max-width: 468px) {
  .industry-card p {
    font-size: 15px;
    color: #555;
    text-align: justify;
  }
}
@media (max-width: 1024px) {
  .industry-card p {
    font-size: 12px !important;
    color: #555;
  }
}

.industry-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}
.image-what-we-do {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.image-container-what-we-do {
  display: flex;
  /* justify-content: start; */
}
@media (max-width: 468px) {
  .paragraph-new {
    width: 125px !important;
  }
}

.contain-container-image {
  display: flex;
  /* justify-content: start; */
  /* gap: 120px; */
}

.about-contact-2 {
  height: 7rem;
  width: 7rem;
  /* line-height: 9rem; */
  background: linear-gradient(
    90deg,
    rgb(1 134 167) 0%,
    rgba(38, 139, 222, 1) 100%,
    rgb(2 154 188) 100%
  ) !important;
  text-align: center;
  font-size: 4rem;
  color: white;
  clip-path: polygon(
    20% 0%,
    100% 0,
    100% 20%,
    100% 80%,
    100% 100%,
    20% 100%,
    0% 80%,
    0 0
  );
  display: inline-block;
}
.about-contact-main-container {
  display: flex;
  align-items: center;
  gap: 2rem;
  /* margin-bottom: 3rem; */
  margin-top: 2rem;
}
