@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
:root {
  --primaryColor: #59aa47;
  --secondaryColor: #8b8e8f;
  --bgColorPrimary: #59aa47;
  --bgColorSecondary: #5c5856;
  --whiteColor: #fff;
  --fontFamily: "Poppins", sans-serif;
}
body {
  background: #fdf9f5;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  box-sizing: border-box;
}
h1 {
  font-size: 40px;
  color: #2596be;
}
h2 {
  font-size: 25px;
}
h3 {
  font-size: 32px !important;
}
h4 {
  font-size: 25px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 15px;
}
img {
  max-width: 100%;
}
p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

body a {
  color: #000;
  text-decoration: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.sectional {
  padding-bottom: 50px;
  padding-top: 20%;
}
.section {
  padding-bottom: 50px;
  padding-top: 50px;
}
.sectionpage {
  background: linear-gradient(to right, #e6fbfb82, #e6fbfb82);
  padding-bottom: 50px;
  padding-top: 50px;
}
.inner-services img {
  margin-bottom: 18px;
}
/* loader CSS */
#nprogress {
  pointer-events: none;
}
#nprogress .bar {
  background: var(--primaryColor);
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
}
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px var(--primaryColor), 0 0 5px var(--secondaryColor);
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0, -4px);
  -ms-transform: rotate(3deg) translate(0, -4px);
  transform: rotate(3deg) translate(0, -4px);
}
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}
#nprogress .spinner-icon {
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  border: 4px solid transparent;
  border-top-color: var(--primaryColor);
  border-left-color: var(--primaryColor);
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}
.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}
.nprogress-custom-parent #nprogress .bar,
.nprogress-custom-parent #nprogress .spinner {
  position: absolute;
}
@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* design part here */
.head-footer-bg {
  background: linear-gradient(
    90deg,
    rgb(255, 255, 255) 55%,
    rgb(255, 255, 255) 80%
  );
}
.footer-bg {
  /* background: linear-gradient(
    140deg,
    rgb(135, 206, 235) 15%,
    rgb(255, 255, 255) 80%
  ); */
  background: #777d8324;
}

.heighthead {
  height: 90px;
}
/* primary button css */
body .btn-primary {
  background: var(--primaryColor);
  color: #fff;
  border-color: transparent;
  outline: none;
}
body .btn-default {
  background: #fff;
  border: solid 1px var(--primaryColor);
  color: var(--primaryColor);
  outline: none;
}
/* body .btn-rounded {
    border-radius: 50px !important;
} */
body .btn-primary:hover,
body .btn-default:hover,
body .btn-primary:active,
body .btn-default:active,
body .btn-primary:focus-visible {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
  background: var(--bgColorPrimary);
  color: #fff !important;
  border-color: transparent !important;
  outline: none;
}

body .btn-primary-two {
  background: var(--bgColorSecondary);
  color: #fff;
  border-color: transparent;
  outline: none;
  border-radius: 0px;
}
body .btn-primary-two:hover {
  background: var(--bgColorSecondary);
  color: #fff;
  border-color: transparent;
  outline: none;
  border-radius: 0px;

  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
  background: var(--secondaryColor);
  color: #fff !important;
  border-color: transparent !important;
  outline: none;
}

body .btn-primary-two {
  background: var(--bgColorSecondary);
  color: #fff;
  border-color: transparent;
  outline: none;
}

body .btn-job {
  background: var(--whiteColor);
  color: #303030;
  border-radius: 5px;
  border: solid 1px #303030;
  width: 100%;
}
body .btn-job:hover {
  background-color: var(--bgColorPrimary) !important;
  color: #fff;
  border-radius: 5px;
  border: solid 0px #303030;
  width: 100%;
}

/* loader CSS */
#nprogress {
  pointer-events: none;
}
#nprogress .bar {
  background: var(--primaryColor);
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
}
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px var(--primaryColor), 0 0 5px var(--secondaryColor);
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0, -4px);
  -ms-transform: rotate(3deg) translate(0, -4px);
  transform: rotate(3deg) translate(0, -4px);
}
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}
#nprogress .spinner-icon {
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  border: 4px solid transparent;
  border-top-color: var(--primaryColor);
  border-left-color: var(--primaryColor);
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}
.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}
.nprogress-custom-parent #nprogress .bar,
.nprogress-custom-parent #nprogress .spinner {
  position: absolute;
}
@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* navigation bar design */

.navbar-nav .nav-item a {
  color: black;
  font-size: 14px;
  font-weight: 600;
}

.navbar-nav .nav-link.active {
  color: var(--bgColorPrimary);
  font-size: 14px;
  font-weight: 600;
}

.navbar-nav .dropdown-menu {
  background-color: #ffffff;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 40px;
  }
  .navbar-expand-lg .navbar-nav {
    max-height: 110px;
  }
}

.sticky-header.navbar-expand-lg .navbar-nav .nav-link {
  padding-left: 40px !important;
}

.sticky-header .navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--bgColorPrimary) !important;
}
.sticky-header .dropdown-item:hover,
.dropdown-item:focus {
  color: var(--whiteColor) !important;
  background-color: var(--bgColorSecondary) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bgColorPrimary);
  text-decoration: none;
  background-color: var(--bgColorSecondary);
}

/* Footer design Here */

.footer {
  padding-top: 40px;
  padding-bottom: 35px;
}

.footer h5 {
  color: var(--bgColorPrimary);
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 40px;
}
.footer p {
  font-size: 16px;
  color: var(--whiteColor);
}
@media (max-width: 768px) {
  .footer p {
    font-size: 14px;
    color: var(--whiteColor);
  }
}

.space5 {
  padding-bottom: 30px;
  color: #000;
}
.space10 {
  padding-bottom: 50px;
}

.space2 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.footer li {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 0rem;
}
.footer-nav {
  padding-left: 0px;
}
.footer-nav li a {
  color: var(--whiteColor);
}
.footer-nav li a:hover {
  color: var(--bgColorPrimary);
}

.footer-nav li span {
  padding-left: 20px;
  color: var(--whiteColor);
}

.footer .footer-nav {
  list-style: none;
}

.social-media a img {
  height: 30px;
  width: 30px;
}

@media screen and (max-width: 767px) {
  .contactuss {
    margin-left: 0px !important;
  }

  .footer h5 {
    padding-bottom: 20px;
  }

  .sliderServices {
    background-image: url("../../public/Assets/servicesbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  }
}

.copy-right {
  background-color: #4b4b4b7d;
  padding-top: 20px;
  padding-bottom: 20px;
}
.copy-right .copy-right-text {
  color: var(--whiteColor);
  text-align: center;
  font-size: 16px;
}

.social-media-list {
  padding-left: 15px;
}

.sticky-header {
  position: fixed !important;
  top: 0 !important;
  width: 100%;
  z-index: 2;
  height: 110px;
}

/* project page design */
.project-bg {
  background-color: #f2f2f2;
  padding-top: 50px;
  padding-bottom: 50px;
}

.project-page .inner-page {
  background-color: #fff;
}

.project-page ul {
  list-style: none;
}
.project-page ul li {
  color: #303030;
  font-size: 24px;
  padding-bottom: 22px;
}

.project-page ul li :hover {
  color: #59aa47;
  background-color: #fff;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
}

.project-page ul li .active {
  font-size: 24px !important;
  font-weight: bold;
  border-bottom: 4px solid var(--bgColorPrimary);
  border-radius: 0px;
  padding-top: 5px;
  padding-bottom: 15px;
  padding-left: 5px;
  background-color: #fff;
}

.project-page .inner-page h3 {
  padding-left: 40px;
  padding-top: 20px;
}

.project-page .inner-page h3 strong {
  color: var(--bgColorPrimary);
}

/* .........All Job Post.... */

.job-slider {
  background-color: #f2f2f2;
}
.job-slider h4 {
  font-size: 50px;
  font-weight: bold;
  color: #303030;
}

.job-slider .job-item {
  background-color: #fff;
  padding: 15px 15px 15px 15px;
  border-top: 4px solid var(--bgColorPrimary);
  border-radius: 5px;
  margin: 10px;
}
.job-slider .job-item strong {
  font-size: 12px;
  color: #000;
}

.job-slider .job-item .job-title {
  font-size: 24px;
  font-weight: bold;
  color: #303030;
  padding-top: 20px;
}
.job-slider .job-item ul {
  list-style: none;
  display: flex;
  padding: 0px;
}
.job-slider .job-item ul li {
  list-style: none;
  background-color: #f2f2f2;
  color: #8b8e8f;
  padding: 10px 25px 10px 25px;
  border-radius: 7px;
  margin: 5px;
}

.job-slider .job-item .job-description {
  padding-top: 10px;
}

/* inner career page */

.section-carrers .year-class ul {
  list-style: none;
  display: flex;
  padding: 0px;
}
.section-carrers .year-class ul li {
  list-style: none;
  background-color: #f2f2f2;
  color: #8b8e8f;
  padding: 10px 25px 10px 25px;
  border-radius: 7px;
  margin: 5px;
}

.section-carrers h1 {
  font-size: 50px;
  font-weight: bold;
}

.share-link {
  padding-left: 20px;
  padding-top: 10px;
}

.arrow {
  position: relative;
  display: inline-block;
  cursor: -webkit-grab;
  cursor: grab;
}

.arrow img {
  transition: transform 0.3s ease-in-out;
}

.arrow:hover img {
  transform: scale(1.1); /* Change the scale value to adjust the pop effect */
}

.section-job {
  padding-top: 50px;
  padding-bottom: 20px;
}

.section-carrers-elemntor .job-elemento-decrip {
  background-color: #f2f2f2;
  padding: 20px 20px 20px 20px;
}

/* .......section-news....... */

/* .section-news{
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: url('/images/two-right-two.png');
    background-repeat: no-repeat;
    background-size: cover;
} */

.news-event-section h2 {
  font-size: 50px;
  font-weight: bold;
}
.inner-event-right {
  padding-bottom: 10px;
}
.inner-event-right img {
  height: 140px;
  width: 100%;
}

.accolades-slider {
  background-color: #5c5856;
}
.accolades-slider .inner-accolades-slider h4 {
  color: #fff;
  font-size: 50px;
  font-weight: bold;
}
.job-slider .job-item .job-description {
  padding-top: 10px;
}

/* Service */

.Servicesimage {
  width: 350px;
  height: 300px;
}

.styleposition {
  position: relative;
  top: 20;
  right: 50;
  margin-top: -290px;
}
.Headtitle {
  text-align: center;
  color: #000;
  font-weight: bold;
  /* margin-left: 20px; */
  text-transform: uppercase;
  font-size: 25px;
}

.slideSecondcate {
  margin-left: 20px;
}

.buttoncustm {
  background-color: #07b5cd;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  color: white !important;
}

.buttoncustmapply {
  background-color: #07b5cd !important;
  padding: 2px 10px 2px 10px !important;
  border-radius: 5px !important;
  color: white !important;
  font-size: 12px !important;
}

.cebttop {
  font-size: 15px !important;
}
.subtitlepara {
  margin-left: 40px;
  margin-top: 30px;
}

.bounce {
  transition: transform 0.3s ease-in-out;
}

.bounce:hover {
  transform: scale(1.05);
}

.social-media-sidebar {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 10px 0 0 10px;
  z-index: 1;
}
.container {
  width: 95%;
  height: auto;
}
.main-container-slider {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-left: 120px;
  width: 650px;
  height: 730px;
  background: linear-gradient(
    90deg,
    rgb(1 134 167) 0%,
    rgba(38, 139, 222, 1) 100%,
    rgb(2 154 188) 100%
  ) !important;
}
.slider-style-container {
  background-size: cover;
  height: 730px;
  min-height: 730px;
}
@media (max-width: 468px) {
  .main-container-slider {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-left: 10px;
    width: 650px;
    height: 550px;
    background: linear-gradient(
      90deg,
      rgb(1 134 167) 0%,
      rgba(38, 139, 222, 1) 100%,
      rgb(2 154 188) 100%
    ) !important;
    /* opacity: 1; */
  }
  .slider-style-container {
    background-size: cover;
    height: 550px;
    min-height: 550px;
  }
}
.social-media-sidebar a {
  color: #fff;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-media-sidebar a:hover {
  transform: scale(1.1);
  color: red;
}

@media (max-width: 768px) {
  .social-media-sidebar {
    top: auto;
    bottom: 10px;
    right: 0px;
    transform: translateY(-910%);
    flex-direction: row;
    z-index: 1;
  }
  .contact-infos {
    flex: 0 0 auto;
    width: 32.333333% !important;
  }
}
@media (max-width: 468px) {
  .social-media-sidebar {
    z-index: 1;
    top: auto;
    bottom: 10px;
    right: 0px;
    transform: translateY(-441%);
    flex-direction: column;
  }
}

/* Contact */

.slidescontact {
  background-color: #fafcfd;
  /* // width: 80% !important; */
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contactuss {
  margin-left: 50px !important;
}
.contactusstext {
  margin-left: 8px;
  color: black;
}

/* .contactusstextemail{
    margin: 20px;

    width: 20%;
    background-color: #00d4ff;
  } */

.socialicon {
  margin-top: 20px;
  font-size: 20px;
  color: #24415f;
  margin: 20px;
}
@media (max-width: 468px) {
  .email-label {
    margin-top: 20px;
  }
}
.desstyle {
  font-size: 15px;
  color: #24415f;
  margin: 0px;
}
.inputstyle {
  border: 1px solid white;
  background-color: #e7e7e7;
  width: 60% !important;
  height: 30px;
  border-radius: 5px;
  margin-top: 10px;
}

.inputstylejob {
  border: 1px solid white;
  background-color: #e7e7e7;
  width: 100% !important;
  height: 30px;
  border-radius: 5px;
  margin-top: 10px;
}
.inputstylearea {
  border: 1px solid gray;
  background-color: #e7e7e7;
  width: 60% !important;
  height: 70px;
  border-radius: 5px;
  margin-top: 10px;
}
.submitbutton {
  margin-top: 10px;
  color: white;
  background-color: #2596be;
  border: 1px solid white;
  width: 60%;
  border-radius: 5px;
}

/* Adjust the arrow in the PhoneInput component */
.react-tel-input .flag-dropdown .selected-flag {
}

.react-tel-input .flag-dropdown .arrow {
  display: none;
}

.react-tel-input .country-list {
  top: 30px; /* Adjust this value based on your input height and padding */
}

@media (max-width: 490px) {
  .contactuss {
    margin-left: 0px !important;
  }
  .inputstyle {
    width: 100% !important;
  }
  .inputstylearea {
    width: 100% !important;
  }
  .mainSectionsec {
    font-size: 12px;
  }
  .topcent {
    margin-top: 0px !important;
  }
  .logstyle {
    width: 90px;
    height: 50px;
  }
  h1 {
    font-size: 25px;
    color: #2596be;
  }

  .buttonstyleapply {
    width: 40%;
    height: 35px;
    padding: 6px 0px 1px 0px;
    border-radius: 20px;

    /* align-items: center;
      display: flex;
      justify-content: center;
      height: 40px; */
    margin-top: 20px;
  }
  .errormsg {
    color: red !important;
  }

  .buttonstylcontact {
    width: 25%;
    padding: 15px 5px 5px 5px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: linear-gradient(80deg, #24415f 41%, #07b5cd 85%);

    align-items: center;
    display: flex;
    justify-content: center;
    height: 40px;
  }
  .contactustext {
    font-size: 12px;
  }
}

/* ourservices */

.sliderServices {
  background-image: url("../../public/Assets/servicesbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  padding-bottom: 200px !important;
}

.Severour {
  color: #07b5cd;
  font-size: 50px !important;
}

.boxservices {
  background: linear-gradient(80deg, #2970a8 41%, #07b5cd 85%);
  margin-top: 20px;
  border-radius: 10px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateX(0);
  width: 300px !important;
}
.boxservices:hover {
  background-color: #07b5cd;
  transform: translateX(10%);
}
.footericon {
  font-size: 25px;
  color: white;
  margin-top: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.footerstextservices {
  margin-left: 10px;
  color: white;
  margin-top: 10px;
  cursor: pointer;
}

/* src/components/ScrollArrow.css */
.scroll-arrow-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-arrow {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  transition: background 0.3s ease;
  z-index: 1;
}

.scroll-arrow:hover {
  transform: scale(1.1);
}

.coursehove:hover {
  color: #07b5cd;
}

/* src/Styles/globals.css */

/* Global CSS */

.head-footer-bg {
  color: #fff;
}

.navbar-brand img {
  max-width: 100%;
  height: auto;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.navbar-nav .nav-item {
  margin: 0 10px; /* Add margin between items */
}

.navbar-nav .nav-a {
  color: #fff;
  text-decoration: none;
}

.navbar-nav .nav-a.active {
  color: #12b338;
}

.navbar-nav .nav-a:hover {
  color: #ddd;
}

.dropdown-menu {
  background-color: #333;
}

.dropdown-item {
  color: #fff;
}

.dropdown-item:hover {
  background-color: #444;
}

.navbar-toggler {
  background: #00a9d3;
  border: none;
}
.button-container {
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
}

.button-container .hover-button {
  height: 50px;
  width: 170px;
  border: none;
  font-size: 16px;
  border-radius: 0 25px;
  background-image: linear-gradient(to right top, #fb0712, #083ec7);
  cursor: pointer;
  color: #fff;
  transition: all 0.5s;
}

.button-container .hover-button:hover {
  height: 50px;
  width: 170px;
  border: none;
  border-radius: 25px 0;
  background-image: linear-gradient(to right top, #fb0712, #083ec7);
  cursor: pointer;
  color: #fff;
}
@media (max-width: 468px) {
  .button-container {
    display: none;
  }
}

/* Center align items and make responsive */
@media (max-width: 991px) {
  .contactuss {
    margin-left: 10px !important;
  }
  .navbar-nav {
    display: block;
    flex-direction: column;
    background-color: #f0f0f0;
    margin-top: 50px;
    width: 100% !important;
    border-radius: 10px;
  }
  .fullwidth {
    width: 100% !important;
  }

  .navbar-nav .nav-item {
    margin: 0 10px; /* Add margin between items */
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

/* Hide the background and adjust toggle button */
.navbar-toggler-icon {
  background-image: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: relative;
}

.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #fff;
}

.navbar-toggler-icon::before {
  top: 8px;
}

.navbar-toggler-icon::after {
  bottom: 8px;
}

.servfooter {
  font-size: 25px;
}
/* From Uiverse.io by Mangesh636 */
.new-style-button {
  background: transparent;
  position: relative;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid rgb(46, 55, 157);
  border-radius: 25px;
  outline: none;
  overflow: hidden;
  color: rgb(255, 255, 255);
  transition: color 0.3s 0.1s ease-out;
  text-align: center;
}

.new-style-button span {
  margin: 10px;
}

.new-style-button::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: "";
  border-radius: 50%;
  display: block;
  width: 20em;
  height: 20em;
  left: -5em;
  text-align: center;
  transition: box-shadow 0.5s ease-out;
  z-index: -1;
}

.new-style-button:hover {
  color: #000000;
  border: 1px solid rgb(64, 129, 204);
}

.new-style-button:hover::before {
  box-shadow: inset 0 0 0 10em rgb(63, 119, 196);
}
.section-container {
  background-image: url("../components/Assets/3-2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  /* background: #f9f9f9; */
  padding: 20px 15px;
  border-radius: 8px;
}
/* starting */
.pos-rel {
  position: relative;
  z-index: 1;
}
.about-left-pics-2 {
  position: relative; /* Establishes a stacking context for children */
  padding: 0.5rem 0.5rem;
  margin-bottom: 100px;
}

.about-left-pics-2::before {
  position: absolute;
  content: "";
  z-index: -1; /* Ensure it's behind other elements */
  top: 40px;
  left: -37px;
  width: 200px;
  height: 230px;
  background: linear-gradient(
    90deg,
    rgb(1 134 167) 0%,
    rgba(38, 139, 222, 1) 100%,
    rgb(2 154 188) 100%
  );
  clip-path: polygon(100% 1%, 0 0, 0 100%);
}

.about-2-1 {
  /* position: relative; */
  z-index: 1; /* Set a base stacking level */
  max-width: 100%;
  transition: all 0.3s ease-out 0s;
  height: auto;
}
@media (max-width: 468px) {
  .about-2-1 {
    border-radius: 65px;
  }
  .about-left-pics-2 {
    margin-bottom: 20px;
  }
  .about-left-pics-2::before {
    display: none;
  }
  .about-exp-yr {
    display: none;
  }
  .about-exp-yr .about-exp-pic {
    display: none;
  }
  .about-exp-yr::before {
    display: none;
  }
  .about-2-2 {
    display: none;
  }
}

.about-2-2 {
  position: absolute; /* Ensure proper placement */
  clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 25% 100%, 0% 50%);
  bottom: -100px;
  left: -37px;
  width: 350px;
  z-index: 0; /* Place behind .about-exp-yr */
}

.about-exp-yr {
  position: absolute;
  bottom: 5rem;
  right: 40px;
  padding: 3rem 17rem 3rem 3rem;
  z-index: 1; /* Ensure this is above .about-2-2 */
}

.about-exp-yr::before {
  position: absolute;
  content: "";
  width: 75%;
  height: 80%;
  top: 67px;
  left: 66px;
  background: linear-gradient(
    90deg,
    rgb(1 134 167) 0%,
    rgba(38, 139, 222, 1) 100%,
    rgb(2 154 188) 100%
  ) !important;
  clip-path: polygon(50% 0%, 100% 0, 100% 60%, 100% 100%, 10% 100%, 0 75%, 0 0);
  z-index: -1;
}
.about-exp-yr .about-exp .heading-2 {
  line-height: 1;
  margin-bottom: 5px;
  color: #fff;
}
.heading-2 {
  position: relative;
  top: 50px;
  left: 42px;
  font-size: 36px;
  font-weight: 700;
  color: white;
}
.about-exp-yr .about-exp .heading-5 {
  color: #fff;
}
.heading-5 {
  position: relative;
  top: 50px;
  left: 42px;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 700;
  color: white;
}
.about-exp-yr .about-exp-pic {
  position: absolute;
  bottom: -32px;
  right: 21px;
  width: 200px;
}

/* ending */
.main-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 15px;
  line-height: 1.4;
}

.highlighted-text {
  font-family: sans-serif;
  color: #59aa47;
}

.description-text {
  font-size: 0.9rem;
  color: #555;
  line-height: 1.6;
  margin-top: 10px;
}
@media (max-width: 468px) {
  .description-text {
    font-size: 16px !important;
    text-align: justify;
    color: #555;
    line-height: 1.6;
    margin-top: 10px;
    width: 90%;
  }
}
/* services */

.box {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.our-services {
  margin-top: 75px;
  padding-bottom: 30px;
  padding: 0 60px;
  min-height: 255px;
  text-align: center;
  border-radius: 10px;
  /* background-color: #fff; */
  transition: all 0.4s ease-in-out;
  box-shadow: 0 0 25px 0 rgba(20, 27, 202, 0.17);
}
@media (max-width: 540px) {
  .our-services {
    margin-top: 75px;
    padding-bottom: 30px;
    padding: 10px;
    min-height: 240px !important;
    text-align: center;
    border-radius: 10px;
    transition: all 0.4s ease-in-out;
    box-shadow: 0 0 25px 0 rgba(20, 27, 202, 0.17);
  }
}
@media (max-width: 1024px) {
  .box {
    position: relative;
    width: 100%;
    padding-right: 1px;
    padding-left: 1px;
  }
  .our-services {
    margin-top: 75px;
    padding-bottom: 30px;
    padding: 10px;
    min-height: 335px;
    text-align: center;
    border-radius: 10px;
    transition: all 0.4s ease-in-out;
    box-shadow: 0 0 25px 0 rgba(20, 27, 202, 0.17);
  }

  .paragraph-inner {
    margin-top: 10px;
    line-height: 2.5vh;
    font-size: 15px;
    text-align: start !important;
  }
}
@media (max-width: 768px) {
  .paragraph-inner {
    margin-top: 10px;
    line-height: 2.5vh;
    font-size: 15px;
    text-align: start !important;
  }
}
.our-services .icon {
  margin-bottom: -21px;
  transform: translateY(-50%);
  text-align: center;
}
.our-services:hover h4,
.our-services:hover p,
.our-services:hover a {
  color: #ffffff;
}
.speedup:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #fb0054 0%, #f55b2a 100%);
}
.settings:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #34b5bf 0%, #210c59 100%);
}
.privacy:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #3615e7 0%, #44a2f6 100%);
}
.backups:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #fc6a0e 0%, #fdb642 100%);
}
.ssl:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #8d40fb 0%, #5a57fb 100%);
}
.database:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #27b88d 0%, #22dd73 100%);
}
.headers-more {
  height: 55px;
}
.paragraph-inner {
  text-align: center;
}
@media (max-width: 468px) {
  .paragraph-inner {
    text-align: justify;
  }
}
@media (max-width: 768px) {
  .paragraph-inner {
    text-align: justify;
  }
}
/* form sections */
.card0 {
  box-shadow: 0px 4px 8px 0px #757575;
  border-radius: 20px;
}

.card1 {
  border-bottom-left-radius: 11px;
  border-top-left-radius: 9px;
  background-color: #006f99;
  height: 104%;
  color: #fff;
  padding-left: 13%;
  padding-right: 13%;
}

.logo {
  margin-top: 50px;
  margin-left: 15px;
  cursor: pointer;
}

.card2 {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.social-connect .fa-google-plus {
  color: #df4b37;
  padding: 10px 12px;
  font-size: 20px;
}

.social-connect .fa-facebook-square {
  color: #3b5998;
  padding: 10px 15px;
  font-size: 20px;
}

.social-connect {
  padding: 3px 0px 3px 3px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
}

.social-connect:hover {
  background-color: #eeeeee;
}

.line {
  height: 1px;
  width: 45%;
  background-color: #e0e0e0;
  margin-top: 10px;
}

.or {
  width: 10%;
}

.text-sm {
  margin-top: 10px;
  font-size: 14px !important;
}

input {
  padding: 10px 12px 10px 12px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  /* margin-bottom: 25px; */
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #2c3e50;
  font-size: 14px;
  letter-spacing: 1px;
}
.phoneinput {
  padding: 10px 12px 10px 12px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #2c3e50;
  font-size: 14px;
  letter-spacing: 1px;
}
textarea {
  padding: 10px 12px 10px 12px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #2c3e50;
  font-size: 14px;
  letter-spacing: 1px;
}

input:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #512da8;
  outline-width: 0;
}

::placeholder {
  color: #eeeeee;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #eeeeee;
}

::-ms-input-placeholder {
  color: #eeeeee;
}

button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}

.btn-blue {
  background-color: #512da8;
  width: 100%;
  color: #fff;
  border-radius: 6px;
}

.btn-blue:hover {
  background-color: #311b92;
  color: #fff;
  cursor: pointer;
}

.card-0 {
  color: #311b92;
  background-color: #fff;
  border-radius: 20px;
  min-height: 352px;
  margin-top: 80px;
  padding: 40px;
}

.carousel-indicators {
  position: absolute;
  bottom: -100px;
  display: -webkit-box !important;
}

.carousel-indicators li {
  cursor: pointer;
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  opacity: 0.5;
  margin: 5px !important;
}

.carousel-indicators li.active {
  opacity: 1;
  width: 50px !important;
  height: 50px !important;
  margin-top: 0px !important;
  border: 2px solid #fff;
}

.carousel-indicators li#li1 {
  background: url("https://i.imgur.com/IjkibdE.jpg") !important;
  background-size: cover !important;
}

.carousel-indicators li#li2 {
  background: url("https://i.imgur.com/oW8Wpwi.jpg") !important;
  background-size: cover !important;
}

.carousel-indicators li#li3 {
  background: url("https://i.imgur.com/EUYNvE1.jpg") !important;
  background-size: cover !important;
}

.carousel-indicators li#li4 {
  background: url("https://i.imgur.com/ndQx2Rg.jpg") !important;
  background-size: cover !important;
}

.carousel-indicators li#li5 {
  background: url("https://i.imgur.com/gazoShk.jpg") !important;
  background-size: cover !important;
}

.profile-pic {
  width: 100px;
  height: auto;
  /* border-radius: 50%; */
  position: absolute;
  top: 23px;
  left: calc(50% - 50px);
}
.mt-5 {
  margin-top: 4rem !important;
}

.contentnew {
  color: #000000;
  font-size: 12px;
  /* display: flex;
  justify-content: space-around; */
}

.social {
  margin-top: 150px;
}

@media screen and (max-width: 991px) {
  .card1 {
    border-bottom-left-radius: 0px;
    border-top-right-radius: 20px;
  }

  .card2 {
    border-bottom-left-radius: 20px;
    border-top-right-radius: 0px;
  }
}
.contactus-section {
  background-color: #f7fafb;
}
.background-protector {
  border-radius: 50px 400px 50px 50px;
  background: linear-gradient(
    90deg,
    #0186a7,
    #268bde 100%,
    #029abc 0
  ) !important;
}
@media (max-width: 468px) {
  .background-protector {
    border-radius: 50px 155px 50px 50px;
    background: linear-gradient(
      90deg,
      #0186a7,
      #268bde 100%,
      #029abc 0
    ) !important;
  }
}
@media (max-width: 1024px) {
  .background-protector {
    border-radius: 50px 155px 50px 50px;
    background: linear-gradient(
      90deg,
      #0186a7,
      #268bde 100%,
      #029abc 0
    ) !important;
  }
}
/* values section */
.title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 15px;
  line-height: 1.4;
  position: relative;
}
.title::before {
  position: absolute;
  left: -29px;
  top: 0;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
  background-color: #7499f1;
  box-shadow: 0px 4px 4px 0px #aaa;
  border-radius: 4px;
  content: "";
}
.title::after {
  position: absolute;
  left: -19px;
  top: 25px;
  height: 14px;
  width: 14px;
  transform: rotate(45deg);
  background-color: #7499f1;
  box-shadow: 0px 4px 4px 0px #aaa;
  border-radius: 4px;
  content: "";
}
/* services */
.services {
  padding: 40px;
  position: relative;
}
.services .square {
  height: 100px;
  width: 100px;
  margin: 30px auto 40px;
  position: relative;
  border-radius: 15px;
  transform: rotate(45deg);
  padding: 10px;
  background-color: #029ab2;
  box-shadow: 0px 0px 27px 0px #ccc;
  border: 2px dotted #eaf3fa;
}
.square i {
  transform: rotate(315deg);
  display: block;
  color: #eaf3fa;
  font-size: 40px;
  padding-top: 17px;
  padding-right: 14px;
}
.icons-sizing {
  margin-left: 19px;
  margin-bottom: 35px;
  object-fit: contain;
}
.services .square::before {
  position: absolute;
  left: -10px;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #fa9746;
  content: "";
}
.services .square::after {
  position: absolute;
  left: 50px;
  top: 66px;
  width: 1px;
  height: 88%;
  background-color: #fa9746;
  content: "";
  transform: rotate(90deg);
}
.services .serv .num {
  width: 60px;
  height: 60px;
  background-color: #7499f1;
  box-shadow: 0px 0px 15px 0px #00a9d3;
  margin: -38px auto 15px auto;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
  padding-top: 7px;
  border: 1px dashed;
}
.services .serv h5 {
  color: #fa9746;
}
.services .serv p {
  color: #777;
}
@media (max-width: 468px) {
  .services .serv p {
    color: #777;
    font-size: 15px;
    text-align: justify;
  }
}
@media (max-width: 1024px) {
  .services .serv p {
    color: #777;
    font-size: 14px;
    text-align: start;
  }
  .services .serv h5 {
    margin-bottom: 15px;
  }
  h5 {
    font-size: 19px;
  }
}
.services .serv hr {
  width: 30px;
  border-top: 3px solid rgb(0 169 211);
}
/* neew codeee */
.custom-core-values-container {
  color: #fff;
  padding: 40px 20px;
  text-align: center;
}

.custom-core-values-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #000;
}

.custom-subtitle {
  font-size: 16px;
  color: #aaa;
}

.custom-core-values {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.custom-value-item {
  position: relative;
  background: #fff;
  color: #000;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: rotate(-2deg);
}

.custom-step-indicator {
  position: absolute;
  top: -10px;
  left: 10px;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 15px;
  border-radius: 5px;
  transform: rotate(-5deg);
}

.custom-step-blue {
  background-color: #007bff;
  color: #fff;
}

.custom-step-red {
  background-color: #dc3545;
  color: #fff;
}

.custom-step-orange {
  background-color: #fd7e14;
  color: #fff;
}

.custom-step-green {
  background-color: #28a745;
  color: #fff;
}

.custom-step-purple {
  background-color: #6f42c1;
  color: #fff;
}

.custom-step-darkgreen {
  background-color: #218838;
  color: #fff;
}

.custom-value-content {
  margin-top: 40px;
  text-align: left;
}

.custom-value-image {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 80px;
  height: 80px;
  transform: rotate(-10deg);
  z-index: 1;
}
/* slider section  */
.why-choose-us {
  padding: 2rem;
  text-align: center;
  background: linear-gradient(180deg, #f9f9f9 40%, #ffffff 100%);
}

.heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--secondary-color);
}

.sub-heading {
  font-size: 1rem;
  color: var(--text-color);
  margin-bottom: 2rem;
}

.sub-heading a {
  color: var(--primary-color);
  text-decoration: none;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
}
.feature-card {
  /* background-color: #bde5eb80; */
  padding: 1.5rem;
  position: relative;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 2px solid #bde5eb80;
  border-radius: 5px;
}

.feature-card:hover {
  transform: translateY(-10px);
}
.feature-card::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: var(--card-bg-color);
  clip-path: polygon(0% 0%, 100% 0%, 100% 70%, 70% 100%, 0% 100%, 0 75%, 0% 0%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.icon-containers {
  width: 60px;
  height: 60px;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  position: absolute;
  top: -30px;
  left: 1.5rem;
  background: linear-gradient(to right, #bfcaf8, #9afbe99d);
  clip-path: polygon(0% 0%, 100% 0%, 100% 70%, 70% 100%, 0% 100%, 0 75%, 0% 0%);
  box-sizing: content-box;
  border-radius: 5px;
}

.icons {
  width: 30px;
  height: 30px;
  z-index: 1;
  position: relative;
}

.feature-title {
  font-size: 16px !important;
  color: var(--secondary-color);
  margin: 1rem 0;
}

.feature-desc {
  font-size: 12px;
  line-height: 1.4;
  text-align: justify;
}

@media (max-width: 768px) {
  .heading {
    font-size: 2rem;
  }

  .sub-heading {
    font-size: 0.9rem;
  }

  .feature-card {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 1.8rem;
  }

  .sub-heading {
    font-size: 0.8rem;
  }

  .feature-title {
    font-size: 1rem;
  }

  .feature-desc {
    font-size: 0.8rem;
  }
}
@media (max-width: 1024px) {
  .heading {
    font-size: 1.8rem;
  }

  .sub-heading {
    font-size: 0.8rem;
  }

  .feature-title {
    font-size: 1rem;
  }

  .feature-desc {
    font-size: 14px;
  }
}
/* search style */
.search {
  border: solid;
  border-color: lightslategrey;
  margin-bottom: auto;
  margin-top: auto;
  height: 70px;
  background-color: #fff;
  border-radius: 40px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_input {
  color: rgb(0, 0, 0);
  border: #006f99;
  width: 550px; /* Default width */
  margin-top: 5px;
  caret-color: transparent;
  line-height: 40px;
  transition: width 0.4s linear;
  padding: 0 10px;
}
.search_input:focus {
  outline: none !important;
  border: none;
  caret-color: red;
}

.search_icon {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  background-color: black;
  cursor: pointer;
}

.search:hover > .search_icon {
  background: red;
  color: #fff;
}

a:link {
  text-decoration: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .search {
    /* height: auto; */
    flex-direction: row;
    padding: 15px;
  }

  .search_input {
    width: 100%; /* Full width on smaller screens */
    margin-top: 10px;
    caret-color: red;
  }

  .search_icon {
    height: 40px;
    width: 40px;
  }
}

@media (max-width: 480px) {
  .search {
    padding: 10px;
  }

  .search_input {
    line-height: 35px;
    font-size: 14px;
  }

  .search_icon {
    height: 40px;
    width: 45px;
  }
}
/* services section */

.service-page-container {
  margin: 70px;
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
}

.service-page-layout {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

/* Left Section */
.service-page-left {
  flex: 1;
  max-width: 400px;
  background-color: #f5f8ff;
  padding: 20px;
  border-radius: 8px;
  min-width: 260px;
}

.service-page-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #e0e0e0;
}

.service-page-item.active {
  background-color: #59bdcc;
  color: #fff;
  border-color: #48e7ff;
}

.service-page-item:hover {
  background-color: #bef1f9;
  color: #333;
}

/* Right Section */
.service-page-right {
  flex: 2;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  min-width: 300px;
}

.service-page-image {
  width: 100%;
  max-width: 900px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.service-page-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.service-page-description {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
}
.container-new-for-small-image {
  margin-top: 20px;
}
.background-smaller-image {
  align-self: center;
  background-image: url("../components/Assets/sidercover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 45px 40px 40px;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 500px;
}
.background-smaller-image::before {
  background-color: #0b182a;
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.service-sidebar__contact__title {
  color: #fff;
  font-size: 20px;
  letter-spacing: -0.96px;
  margin: 100px 0px 44px 0px;
  max-width: 200px;
  font-family: sans-serif;
  font-weight: 800;
}
.hiredots-btn {
  background-color: #5ebfce;
  border: none;
  color: #fff;
  display: inline-block;
  font-family: Manrope, sans-serif;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  vertical-align: middle;
  z-index: 1;
  font-size: 12px;
  font-weight: 700;
  height: 38px;
  padding: 9px 25px;
}
.hiredots-btn:hover {
  color: var(--hiredots-white, #fff);
}
.hiredots-btn:after {
  background: var(--hiredots-primary, #0b2f81);
  bottom: 0;
  content: "";
  height: 120px;
  left: -10px;
  position: absolute;
  transform: skewX(25deg);
  transition: 05s;
  z-index: -1;
  width: 0;
}
/* Responsive Design */
@media (max-width: 1024px) {
  .service-page-container {
    padding: 20px;
  }

  .service-page-layout {
    flex-direction: column;
  }

  .service-page-left {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .service-page-right {
    padding: 15px;
  }

  .service-page-title {
    font-size: 20px;
  }

  .service-page-description {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .service-page-left {
    padding: 15px;
  }

  .service-page-item {
    font-size: 14px;
  }

  .service-page-right {
    padding: 10px;
  }

  .service-page-title {
    font-size: 18px;
  }

  .service-page-description {
    font-size: 13px;
  }
  .background-smaller-image {
    display: none;
  }
}

@media (max-width: 480px) {
  .service-page-container {
    margin: 0px;
  }
  .service-page-layout {
    flex-direction: column;
    gap: 10px;
  }

  .service-page-left,
  .service-page-right {
    padding: 10px;
  }

  .service-page-title {
    font-size: 16px;
  }

  .service-page-description {
    font-size: 12px;
  }
  .background-smaller-image {
    display: none;
  }
}
/* custom button */
.custom-btn {
  width: 140px;
  height: 56px;
  overflow: hidden;
  border: none;
  color: #010101;
  background: none;
  position: relative;
  padding-bottom: 2em;
  cursor: pointer;
  margin-bottom: 5px;
}

.custom-btn > div,
.custom-btn > svg {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}

.custom-btn:before {
  content: "";
  position: absolute;
  height: 2px;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: scaleX(0);
  transform-origin: bottom right;
  background: currentColor;
  transition: transform 0.25s ease-out;
}

.custom-btn:hover:before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.custom-btn .custom-btn-clone > *,
.custom-btn .custom-btn-text > * {
  opacity: 1;
  font-size: 18px;
  transition: 0.2s;
  margin-left: 4px;
}

.custom-btn .custom-btn-clone > * {
  transform: translateY(60px);
}

.custom-btn:hover .custom-btn-clone > * {
  opacity: 1;
  transform: translateY(0px);
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.custom-btn:hover .custom-btn-text > * {
  opacity: 1;
  transform: translateY(-60px);
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.custom-btn:hover .custom-btn-clone > :nth-child(1) {
  transition-delay: 0.15s;
}

.custom-btn:hover .custom-btn-clone > :nth-child(2) {
  transition-delay: 0.2s;
}

.custom-btn:hover .custom-btn-clone > :nth-child(3) {
  transition-delay: 0.25s;
}

.custom-btn:hover .custom-btn-clone > :nth-child(4) {
  transition-delay: 0.3s;
}

/* Icon style and hover */
.custom-btn svg {
  width: 20px;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(-50deg);
  transition: 0.2s ease-out;
}

.custom-btn:hover svg {
  transform: translateY(-50%) rotate(360deg);
}
.Bullet-in {
  object-fit: contain;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-top: 7px;
}
.dividers {
  display: flex;
}
